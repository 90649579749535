import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
    Alert,
    Spinner,
} from '@material-tailwind/react'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { auth, database, storage } from '../fireabase-config';
import { addDocument, updateHeadings } from '../utils/documents';
import { onValue, ref as dbRef } from 'firebase/database';
import DocCard from '../components/doc-card';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Search from "../assets/search.svg";
import PageNotFound from "../assets/not-found.jpg";

const DashboardModule = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [user] = useAuthState(auth);
    const userName = localStorage.getItem("userName");
    const [docLoading, setDocLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headingLoading, setHeadingLoading] = useState(false);
    const [formData, setFormData] = useState({ name: "", description: "", liveUrl: "" });
    const [headingData, setHeadingData] = useState({ heading: "", subHeading: "" });
    const [editHeadingData, setEditHeadingData] = useState({ heading: "", subHeading: "" });
    const [icon, setIcon] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [filterDocuments, setFilterDocuments] = useState([]);
    const [noData, setNoData] = useState(false)
    const handleOpen = () => setOpen(!open);
    const handleAddDoc = async () => {
        if (icon == null || formData.name == "" || formData.description == "" || formData.liveUrl == "") {
            return;
        }
        setLoading(true);
        var file_name = Date.now() + '.' + icon.name.split('.')[1];
        const path = `Document_Icons/${file_name}`;
        const storageRef = ref(storage, path);
        const uploadFile = uploadBytesResumable(storageRef, icon);
        uploadFile.on("state_changed",
            (snapshot) => { },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
                    saveDocument({ downloadURL })
                });
            }
        );
    }
    const saveDocument = async ({ downloadURL }) => {
        const name = localStorage.getItem("userName");
        let nodeName = formData.name
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
        let data = {
            name: formData.name,
            description: formData.description,
            logo: downloadURL,
            sections: [],
            liveUrl: formData.liveUrl,
            status: 'Draft',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };
        const addNewDoc = await addDocument({ userName: name, nodeName: nodeName, data: data })
        if (addNewDoc == 1) {
            setNoData(false);
            getDocuments();
            setFormData({ name: "", description: "" })
            setLoading(false);
            setOpen(false);
            setOpenAlert(true);
            setTimeout(() => {
                setOpenAlert(false);
            }, 2000);
        }
    }
    const getDocuments = () => {
        setDocLoading(true);
        const userDocsRef = dbRef(database, `documents/${params.username}`);
        try {
            const unsubscribe = onValue(userDocsRef,
                (snapshot) => {
                    if (!snapshot.exists()) {
                        setNoData(true);
                        setDocuments([]);
                        setFilterDocuments([]);
                        setDocLoading(false);
                        return;
                    }
                    const dataArray = Array.from(Object.entries(snapshot.val()),
                        ([key, value]) => ({
                            key,
                            ...value
                        })
                    );
                    setDocuments(dataArray);
                    setFilterDocuments(dataArray);
                    setDocLoading(false);
                },
                (error) => {
                    console.error('Error fetching documents:', error);
                    setDocLoading(false);
                },
                {
                    skipMetadataChanges: true
                }
            );
            return unsubscribe;
        } catch (error) {
            console.error('Error setting up real-time listener:', error);
            setDocLoading(false);
            return () => { };
        }
    };
    const getHeading = async () => {
        onValue(dbRef(database, 'userNames/' + params.username), (snapshot) => {
            const data = snapshot.val();
            if (data == null) return;
            setHeadingData({
                heading: data.heading,
                subHeading: data.subHeading,
            })
        })
    }
    const handleEditHeading = async () => {
        if (editHeadingData.heading == "") return;
        setHeadingLoading(true);
        let data = {
            heading: editHeadingData.heading,
            subHeading: editHeadingData.subHeading,
        }
        const update = await updateHeadings({ userName: params.username, data: data })
        if (update == 1) {
            setOpenEdit(false);
        }
        setHeadingLoading(false);
    }
    useEffect(() => {
        getHeading();
    }, [])
    useEffect(() => {
        const unsubscribe = getDocuments();
        return () => unsubscribe();
    }, []);
    const filterDocs = (value) => {
        return filterDocuments.filter((data) => {
            // Check if name matches
            if (data.name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
            // Check if description matches
            if (data.description.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }

            // Check if any key in sections object matches
            for (const sectionKey in data.sections) {
                const sectionValue = data.sections[sectionKey];

                // If section value is a string, check if it matches
                if (typeof sectionValue === 'string' && sectionValue.toLowerCase().includes(value.toLowerCase())) {
                    return true;
                }

                // If section value is an object, iterate over its keys and check if any value matches
                if (typeof sectionValue === 'object') {
                    for (const subKey in sectionValue) {
                        const subValue = sectionValue[subKey];
                        if (typeof subValue === 'string' && subValue.toLowerCase().includes(value.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }

            // If no match found
            return false;
        });
    };
    return (
        <>
            <Alert
                open={openAlert}
                color="green"
                className="absolute right-3 top-2 w-[30%]"
                onClose={() => setOpenAlert(false)}
            >
                Document added successfully!
            </Alert>
            {docLoading && <div className='flex items-center justify-center py-10'><Spinner /></div>}
            {!docLoading && (
                <>
                    {noData == false && (
                        <>
                            <div className='h-64 mb-6 bg-[#0D182E] flex flex-col items-center justify-center text-white px-2 relative'>
                                <p className='text-lg md:text-[50px] font-medium leading-[56px]'>{headingData.heading}</p>
                                <div className='flex items-center w-[80%] md:w-1/2 justify-center gap-3 mt-3'>
                                    <div className='rounded-lg bg-transparent border border-white text-white w-full md:w-[40%] lg:w-[30%] px-4 py-2 flex gap-2'>
                                        <img src={Search} alt='search-icon' />
                                        <input
                                            className='focus:outline-none w-full bg-transparent'
                                            placeholder='Search for projects...'
                                            onChange={(e) => setDocuments(filterDocs(e.target.value))}
                                        />
                                    </div>
                                    {/* <div className='bg-white text-[#0D182E] capitalize text-[13px] px-8 py-[11px] rounded-lg'>
                        Search
                    </div> */}
                                </div>
                                {params.username == userName && user && (
                                    <div onClick={() => {
                                        setEditHeadingData({
                                            heading: headingData.heading,
                                            subHeading: headingData.subHeading
                                        })
                                        setOpenEdit(true)
                                    }
                                    } className='flex items-center justify-center rounded-full border p-2 absolute cursor-pointer top-2 right-2'>
                                        <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16"> <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" /> <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" /> </svg>
                                    </div>
                                )}
                            </div>
                            {documents.length > 0 && (
                                <div className='flex flex-col gap-3 max-w-[1300px] m-auto'>
                                    {documents.map((item, index) => (
                                        <DocCard params={params} key={index} item={item} />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                    {noData == true && (
                        <div className='flex items-center justify-center'>
                            <img src={PageNotFound} />
                        </div>
                    )}
                    {params.username == userName && (
                        <div className='flex items-center justify-center px-2 my-4'>
                            <Button className='w-full md:w-[20%] rounded-lg bg-[#0D182E] capitalize text-xl' onClick={handleOpen}>
                                <span className='flex items-center justify-center gap-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" /> </svg>
                                    Add Document
                                </span>
                            </Button>
                        </div>
                    )}
                </>
            )}
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Add Document</DialogHeader>
                <DialogBody className='space-y-2'>
                    <Input
                        label='Name'
                        type='text'
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        minLength={6}
                    />
                    <Input
                        label='Url'
                        type='text'
                        value={formData.liveUrl}
                        onChange={(e) => setFormData({ ...formData, liveUrl: e.target.value })}
                    />
                    <Textarea
                        label='Description'
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        minLength={50}
                        maxLength={245}
                    />
                    <Input
                        label='Icon'
                        type='file'
                        onChange={(e) => setIcon(e.target.files[0])}
                        minLength={6}
                    />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button loading={loading} variant="gradient" className='bg-[#0D182E]' onClick={handleAddDoc}>
                        <span>Add</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openEdit} handler={() => setOpenEdit(!openEdit)}>
                <DialogHeader>Edit</DialogHeader>
                <DialogBody className='space-y-2'>
                    <Input
                        label='Heading'
                        type='text'
                        value={editHeadingData.heading}
                        onChange={(e) => setEditHeadingData({ ...editHeadingData, heading: e.target.value })}
                    />
                    {/* <Input
                        label='Sub Heading'
                        type="text"
                        value={editHeadingData.subHeading}
                        onChange={(e) => setEditHeadingData({ ...editHeadingData, subHeading: e.target.value })}
                    /> */}
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setOpenEdit(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button loading={headingLoading} variant="gradient" className='bg-[#0D182E]' onClick={handleEditHeading}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default DashboardModule