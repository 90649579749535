import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    Button,
    DialogFooter,
    DialogBody,
    DialogHeader,
    Dialog,
    Input,
} from "@material-tailwind/react";
import { deletePath } from '../utils/documents';
import File from "../assets/article.svg";

const Article = ({ item, id, userName, docKey, sectionKey }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const handleOpenDelete = () => setOpenDelete(!openDelete);
    const deleteArticle = async () => {
        setLoading(true);
        const res = await deletePath({ path: `documents/${params.username}/${docKey}/sections/${sectionKey}/articles/${item.key}` });
        if (res == 1) {
            setOpenDelete(false);
        }
        setLoading(false)
    }
    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                className='capitalize mt-2 text-sm hover:underline cursor-pointer touch-none select-none flex items-center justify-between group'
            >
                <span onClick={() => navigate(`/${params.username}/${docKey}/${sectionKey}/${item.key}`)} className='flex gap-2'>
                    <img src={File} />
                    {item.title}
                </span>
                {params.username == userName && (
                    <p onClick={() => setOpenDelete(true)} className='invisible group-hover:visible'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" /> </svg>
                    </p>
                )}
                <Dialog open={openDelete} size='md' handler={handleOpenDelete}>
                    <DialogHeader className='flex items-center justify-between'>
                        Delete Article
                        <span className='cursor-pointer'>
                            <svg onClick={() => setOpenDelete(false)} height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                        </span>
                    </DialogHeader>
                    <DialogBody className='space-y-2 h-full'>
                        <p>This action is irreversible, Are you sure you want to delete?</p>
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={() => setOpenDelete(false)}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button loading={loading} variant="gradient" color="red" onClick={deleteArticle}>
                            <span>Delete</span>
                        </Button>
                    </DialogFooter>
                </Dialog>
            </div>
        </>
    )
}

export default Article