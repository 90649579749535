import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDOoNdDoplFmFw_MF5uZ4I6lufpoIsBXMM",
    authDomain: "uptechdocs.firebaseapp.com",
    databaseURL: "https://uptechdocs-default-rtdb.firebaseio.com",
    projectId: "uptechdocs",
    storageBucket: "uptechdocs.appspot.com",
    messagingSenderId: "486176419620",
    appId: "1:486176419620:web:d85713a85b6ea019248db1",
    measurementId: "G-Z8L4GQF0YE"
};

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account"
});

export const auth = getAuth(app);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const database = getDatabase(app);
export const storage = getStorage(app);
