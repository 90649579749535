import React from 'react'
import ArticleModule from '../modules/article'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../fireabase-config';
import Navbar from '../components/navbar';
import { Spinner } from '@material-tailwind/react';

const Articles = () => {
    const [user, loading] = useAuthState(auth);
    if (loading) {
        return (
            <div>
                <Navbar user={user} />
                <div className='flex items-center justify-center py-10'>
                    <Spinner />
                </div>
            </div>
        )
    }
    return (
        <div>
            <Navbar user={user} />
            <ArticleModule />
        </div>
    )

}

export default Articles