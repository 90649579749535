import { onValue, ref } from 'firebase/database';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, database, storage } from '../fireabase-config';
import { getDownloadURL, ref as storageReference, uploadBytesResumable } from "firebase/storage"
import moment from 'moment';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Drawer,
  Button,
  DialogFooter,
  DialogBody,
  DialogHeader,
  Dialog,
  Input,
} from "@material-tailwind/react";
import ArticlesList from '../components/articles-list';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import { getAllArticlesFromDocument, updateSectionArticle } from '../utils/documents';
import { useAuthState } from 'react-firebase-hooks/auth';
import Calander from "../assets/calender.svg";

Quill.register('modules/blotFormatter', BlotFormatter);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-3 w-3 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const ArticleModule = () => {
  const params = useParams();
  const userName = localStorage.getItem("userName");
  const navigate = useNavigate();
  const reactQuillRef = useRef(null);
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [sections, setSections] = useState([]);
  const [value, setValue] = useState('');
  const [formData, setFormData] = useState({
    title: "",
  })
  const [articleData, setArticleData] = useState(null);
  const [allArticles, setAllArticles] = useState([]);
  const [breadcrumbSection, setBreadCrumbSection] = useState(params.sectionname)
  const handleOpenEdit = () => setOpenEdit(!openEdit);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const getSections = async () => {
    onValue(ref(database, `documents/${params.username}/${params.docname}/sections`), (snapshot) => {
      if (snapshot.val() == null) return;
      const data = snapshot.val();
      const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
      const sortedArray = dataArray.sort((a, b) => a.position - b.position);
      const activeAccordion = sortedArray.findIndex(item => item.key === params.sectionname);
      if (params.sectionname == undefined || params.sectionname == null) {
        setOpen(0);
        getArticleFromSection({ section: sortedArray[0].key })
      } else {
        setOpen(activeAccordion);
        getArticle();
      }
      setSections(sortedArray);
    })
  }
  const getArticleFromSection = async ({ section }) => {
    onValue(ref(database, `documents/${params.username}/${params.docname}/sections/${section}/articles`), (snapshot) => {
      if (snapshot.val() == null) return;
      const data = snapshot.val();
      const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
      const sortedArray = dataArray.sort((a, b) => a.position - b.position);
      setArticleData(sortedArray[0]);
      setBreadCrumbSection(section)
    })
  }
  const getArticle = async () => {
    if (params.articlename == undefined) {
      onValue(ref(database, `documents/${params.username}/${params.docname}/sections/${params.sectionname}/articles/`), (snapshot) => {
        if (snapshot.val() == null) return;
        const data = snapshot.val();
        const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
        const sortedArray = dataArray.sort((a, b) => a.position - b.position);
        setArticleData(sortedArray[0]);
      })
    } else {
      onValue(ref(database, `documents/${params.username}/${params.docname}/sections/${params.sectionname}/articles/${params.articlename}`), (snapshot) => {
        if (snapshot.val() == null) return;
        const data = snapshot.val();
        setArticleData(data)
      })
    }
  }
  const getAllArticles = async () => {
    const res = await getAllArticlesFromDocument({ path: `documents/${params.username}/${params.docname}` });
    if (res != 0) {
      const articlesWithPath = res.map((section) => {
        const path = `${params.username}/${params.docname}/${section.sectionKey}/${section.key}`;
        return { ...section, path }
      });
      articlesWithPath.sort((a, b) => a.sectionPosition - b.sectionPosition);
      setAllArticles(articlesWithPath);
    }
  }
  const openEditModal = async () => {
    setFormData({
      title: articleData.title
    })
    setValue(articleData.documentContent);
    setOpenEdit(true)
  }
  const editArticleData = async () => {
    const userName = localStorage.getItem("userName");
    if (formData.title == "" || value == "") return;
    setLoading(true);
    let data = {
      title: formData.title,
      documentContent: value,
      updatedAt: new Date().toISOString(),
    };
    const update = await updateSectionArticle({ userName: userName, documentKey: params.docname, sectionKey: params.sectionname, articleKey: params.articlename, data: data })
    if (update == 1) {
      setOpenEdit(false);
    }
    setLoading(false);
  }
  useEffect(() => {
    getSections();
    getAllArticles();
    setOpenDrawer(false);
  }, [params])
  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        var file_name = Date.now() + '.' + file.name.split('.')[1];
        const path = `Document_Icons/${file_name}`;
        const storageRef = storageReference(storage, path);
        const uploadFile = uploadBytesResumable(storageRef, file);
        uploadFile.on("state_changed",
          (snapshot) => { },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
              const quill = reactQuillRef.current;
              if (quill) {
                const range = quill.getEditorSelection();
                range && quill.getEditor().insertEmbed(range.index, "image", downloadURL);
              }
            });
          }
        );
      }
    };
  }, []);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ['clean'], // remove formatting button
          ['link', 'image'], // link and image, video
        ],
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
        handlers: {
          image: imageHandler,
        },
      },
      blotFormatter: {
        overlay: {
          style: {
            border: '2px solid red',
          },
        },
      },
    }),
    []
  );
  return (
    <div className='flex flex-col md:flex-row items-start w-full m-auto'>
      <div className='w-full md:w-[20%] bg-[#0D182E] pt-4 px-10 hidden md:flex flex-col gap-3 min-h-[95vh] max-h-[95vh] no-scrollbar'>
        {sections.length > 0 && (
          <>
            {sections.map((item, index) => (
              <Accordion key={index} open={open === index} icon={<Icon id={index} open={open} />} >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className={`capitalize text-lg font-medium leading-6 border-0 py-3 px-2 rounded-lg ${open == index ? "bg-white" : "text-white hover:text-white"}`}
                >
                  {item.name}
                </AccordionHeader>
                <AccordionBody className="m-0 p-0">
                  <ArticlesList
                    activeArticle={articleData}
                    sectionKey={item.key}
                    params={params}
                  />
                </AccordionBody>
              </Accordion>
            ))}
          </>
        )}
      </div>
      <div className='md:hidden w-full flex items-center justify-between p-3'>
        <svg onClick={() => setOpenDrawer(true)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" /> </svg>
        {articleData != null && (
          <div className='flex justify-end items-center gap-2'>
            <p>Updated: {moment(articleData.createdAt).format('M-D-YY, h:mm A')}</p>
            {params.username == userName && (
              <span className='cursor-pointer'>
                <svg onClick={openEditModal} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16"> <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" /> <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" /> </svg>
              </span>
            )}
          </div>
        )}
      </div>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} className="p-4 bg-[#0D182E]">
        {sections.length > 0 && (
          <>
            {sections.map((item, index) => (
              <Accordion key={index} open={open === index} icon={<Icon id={index} open={open} />} >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className={`capitalize text-base font-medium leading-6 border-0 py-3 px-2 rounded-lg ${open == index ? "bg-white" : "text-white hover:text-white"}`}
                >
                  {item.name}
                </AccordionHeader>
                <AccordionBody className="m-0 p-0">
                  <ArticlesList
                    activeArticle={articleData}
                    sectionKey={item.key}
                    params={params}
                  />
                </AccordionBody>
              </Accordion>
            ))}
          </>
        )}
      </Drawer>
      <div className='w-full md:w-[60%] md:max-w-[60%] overflow-auto p-3 md:p-10 min-h-[95vh] max-h-[95vh] no-scrollbar border-l border-r border-gray-300' >
        {articleData != null && (
          <>
            <div className='flex items-center justify-between mb-4 pb-2 text-xs md:text-sm bg-white'>
              <p className='capitalize'>{breadcrumbSection} <span className='mx-2'>{"/"}</span> {articleData.title}</p>
            </div>
            <div className='article-body overflow-x-auto' dangerouslySetInnerHTML={{ __html: articleData.documentContent }} />
            <div className='flex items-center justify-between mt-2 md:mt-8 mb-5'>
              {allArticles && allArticles.length > 0 && (
                <>
                  {allArticles.map((article, index) => {
                    if (article.path === `${params.username}/${params.docname}/${params.sectionname}/${params.articlename}`) {
                      const isNotFirst = index !== 0;
                      const isNotLast = index !== allArticles.length - 1;
                      let prevIndex = null;
                      let nextIndex
                      if (isNotFirst) {
                        prevIndex = index - 1;
                      }
                      if (isNotLast) {
                        nextIndex = index + 1;
                      }
                      return (
                        <>
                          {prevIndex != null && (
                            <Button variant='outlined' className='cursor-pointer' onClick={() => navigate(`../${allArticles[prevIndex].path}`, { replace: true })}>Previous Article</Button>
                          )}
                          {nextIndex != null && (
                            <Button variant='outlined' className='cursor-pointer' onClick={() => navigate(`../${allArticles[nextIndex].path}`, { replace: true })}>Next Article</Button>
                          )}
                        </>
                      );
                    }
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className='w-full hidden md:block md:w-[20%] p-5'>
        {articleData != null && (
          <div className='flex flex-col justify-center md:ml-10 gap-2'>
            <div className='flex items-center justify-start gap-3'>
              <img src={Calander} />
              <p>Last updated on:</p>
            </div>
            <p className='text-base flex gap-3 items-center font-medium leading-6'>
              {moment(articleData.createdAt).format('M-D-YY, h:mm A')}
              {params.username == userName &&(
                <span className='cursor-pointer'>
                  <svg onClick={openEditModal} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16"> <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" /> <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" /> </svg>
                </span>
              )}
            </p>
          </div>
        )}
      </div>
      <Dialog open={openEdit} size='xxl' handler={handleOpenEdit}>
        <DialogHeader className='flex items-center justify-between'>
          Edit Article
          <span className='cursor-pointer'>
            <svg onClick={() => setOpenEdit(false)} height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
          </span>
        </DialogHeader>
        <DialogBody className='space-y-2 h-full'>
          <Input
            label='Title'
            type='text'
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            minLength={6}
          />
          <ReactQuill ref={reactQuillRef} style={{ height: "80%", maxHeight: "80%" }} theme="snow" modules={modules} value={value} onChange={setValue} />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpenEdit(false)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button loading={loading} variant="gradient" className='bg-[#0D182E]' onClick={editArticleData}>
            <span>Save</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default ArticleModule