import { get, ref, set, update } from "firebase/database";
import { database } from "../fireabase-config";

export async function addSocialSignupData({ method, data, userName }) {
    let userData = {
        signInMethod: method,
        userName: userName,
        email: data.user.email,
        photoURL: data.user.photoURL,
        uid: data.user.uid,
        fullName: data.user.displayName,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        coverImage: '',
        firstMetaData: data.user.metadata,
        lastMetaData: data.user.metadata,
    };
    try {
        console.log("I am here")
        const res = await set(ref(database, 'users/' + data.user.uid), userData);
        const userNameRes = await set(ref(database, 'userNames/' + userName), {
            userName: userName,
            heading: "Documentation",
            subHeading: "Everything you need to get your software documentation online."
        });
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function updateSocialSignupData({ method, data }) {
    let userData = {
        signInMethod: method,
        email: data.user.email,
        uid: data.user.uid,
        fullName: data.user.displayName,
        updatedAt: new Date().toISOString(),
        lastMetaData: data.user.metadata,
    };
    try {
        const user = await get(ref(database, 'users/' + data.user.uid));
        const res = await update(ref(database, 'users/' + data.user.uid), userData);
        localStorage.setItem("userName", user.val().userName);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function updateUserName({ userName, uid }) {
    try {
        const checkUser = await get(ref(database, 'userNames/' + userName));
        console.log(checkUser.exists());
        if (checkUser.exists()) return 2;
        const userNameRes = await set(ref(database, 'userNames/' + userName), { userName: userName });
        const res = await update(ref(database, 'users/' + uid), { userName: userName });
        console.log(userName, uid)
        localStorage.setItem("userName", userName);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}