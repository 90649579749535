import { Route, Routes } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import Login from "./screens/login";
import Articles from "./screens/articles";
import PageNotFound from "./screens/page-not-found";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/:username" element={<Dashboard />} />
      <Route path="/:username/:docname/:sectionname/:articlename" element={<Articles />} />
      <Route path="/:username/:docname/:sectionname" element={<Articles />} />
      <Route path="/:username/:docname" element={<Articles />} />
      <Route
        path="/page-not-found"
        element={<PageNotFound />}
      />
      <Route
        path="*"
        element={<PageNotFound />}
      />
    </Routes>
  );
}

export default App;
