import React from 'react'
import Navbar from '../components/navbar'
import PageNotFoundIcon from "../assets/pagenotfound.jpg"

const PageNotFound = () => {
    return (
        <>
            <Navbar user={null} />
            <div className='flex items-center justify-center my-32'>
                <img src={PageNotFoundIcon} />
            </div>
        </>
    )
}

export default PageNotFound