import React from 'react'
import SectionCard from './section-card'
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'

const SectionRow = ({ sections, docKey }) => {
    return (
        <div className='flex flex-wrap items-start gap-2 w-full md:w-full'>
            <SortableContext items={sections} strategy={horizontalListSortingStrategy}>
                {sections.map((item) => (
                    <SectionCard item={item} id={item.key} key={item.key} docKey={docKey} />
                ))}
            </SortableContext>
        </div>
    )
}

export default SectionRow