import {
    Card,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
} from '@material-tailwind/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import { addSectionArticle, deletePath } from '../utils/documents';
import { onValue, ref, update } from 'firebase/database';
import { getDownloadURL, ref as storageReference, uploadBytesResumable } from "firebase/storage"
import { database, storage } from '../fireabase-config';
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import ArticleColumn from './article-col';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useParams } from 'react-router-dom';

Quill.register('modules/blotFormatter', BlotFormatter);

const SectionCard = ({ item, docKey, id }) => {
    const params = useParams();
    const userName = localStorage.getItem("userName");
    const reactQuillRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [formData, setFormData] = useState({
        title: "",
    })
    const [sectionKey, setSectionKey] = useState(item.key)
    const [articles, setArticles] = useState([]);
    const handleOpen = () => setOpen(!open);
    const handleOpenDelete = () => setOpenDelete(!openDelete);
    const addArticle = async () => {
        if (formData.title == "" || value == "") {
            return;
        }
        setLoading(true);
        let userName = localStorage.getItem('userName');
        let nodeName = formData.title
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
        let position = articles.length > 0 ? articles[articles.length - 1].position + 1 : 0;
        let data = {
            title: formData.title,
            documentContent: value,
            status: 'Draft',
            position: position,
            key: nodeName,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };
        const add = await addSectionArticle({
            userName: userName,
            documentKey: docKey,
            sectionKey: item.key,
            articleKey: nodeName,
            data: data
        })
        if (add == 1) {
            setFormData({ title: "" })
            setValue("");
            setLoading(false);
            setOpen(false);
        }
    }
    const getArticles = async () => {
        onValue(ref(database, `documents/${params.username}/${docKey}/sections/${item.key}/articles`), (snapshot) => {
            if (snapshot.val() == null) return;
            const data = snapshot.val();
            const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
            const sortedArray = dataArray.sort((a, b) => a.position - b.position);
            setArticles(sortedArray);
        })
    }
    useEffect(() => {
        getArticles();
    }, [])
    const getTaskPos = id => articles.findIndex(article => article.key === id);
    const handleDragEnd = event => {
        const { active, over } = event;
        if (active == null || over == null) return;
        if (active.id === over.id) return;
        const originalPos = getTaskPos(active.id);
        const newPos = getTaskPos(over.id);
        const activeItem = update(ref(database, `documents/${userName}/${docKey}/sections/${sectionKey}/articles/${active.id}`), { position: newPos });
        const overItem = update(ref(database, `documents/${userName}/${docKey}/sections/${sectionKey}/articles/${over.id}`), { position: originalPos });
    }
    const { attributes, listeners, setNodeRef, transform, transition, isOver, isDragging } = useSortable({ id })
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )
    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            if (input !== null && input.files !== null) {
                const file = input.files[0];
                var file_name = Date.now() + '.' + file.name.split('.')[1];
                const path = `Document_Icons/${file_name}`;
                const storageRef = storageReference(storage, path);
                const uploadFile = uploadBytesResumable(storageRef, file);
                uploadFile.on("state_changed",
                    (snapshot) => { },
                    (error) => {
                        alert(error);
                    },
                    () => {
                        getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
                            const quill = reactQuillRef.current;
                            if (quill) {
                                const range = quill.getEditorSelection();
                                range && quill.getEditor().insertEmbed(range.index, "image", downloadURL);
                            }
                        });
                    }
                );
            }
        };
    }, []);
    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    [{ direction: 'rtl' }], // text direction
                    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],
                    ['clean'], // remove formatting button
                    ['link', 'image'], // link and image, video
                ],
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
                handlers: {
                    image: imageHandler
                },
            },
            blotFormatter: {
                overlay: {
                    style: {
                        border: '2px solid red',
                    },
                },
            },
        }),
        []
    );
    const deleteSection = async () => {
        const res = await deletePath({ path: `documents/${params.username}/${docKey}/sections/${item.key}` });
        if (res == 1) {
            setOpenDelete(false);
        }
    }
    return (
        <div className={`rounded-lg border border-transparent hover:border hover:border-[#0D182E] bg-[#F5F5F5] p-2 min-w-full w-full md:min-w-[30%] md:w-[30%] select-none transition-all duration-200 cursor-move ${isOver ? "border border-[#0D182E]" : ""}`}>
            <div
                ref={setNodeRef}
                {...attributes}
                {...listeners}
                style={style}
                draggable
            >
                <div>
                    <div className='flex items-center justify-between'>
                        <p className='capitalize mb-2 text-[22px] text-black font-medium'>{item.name}</p>
                        {params.username == userName && (
                            <p onClick={() => setOpenDelete(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" /> </svg>
                            </p>
                        )}
                    </div>
                    {articles.length > 0 && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCorners}
                            onDragEnd={handleDragEnd}
                        >
                            <ArticleColumn articles={articles} userName={userName} docKey={docKey} sectionKey={sectionKey} />
                        </DndContext>
                    )}
                </div>
            </div>
            {params.username == userName && (
                <p onClick={handleOpen} className='mt-2 hover:underline cursor-pointer text-md text-[#0D182E] font-medium'>Add New article</p>
            )}
            <Dialog open={open} size='xxl' handler={handleOpen}>
                <DialogHeader className='flex items-center justify-between'>
                    Add Article
                    <span className='cursor-pointer'>
                        <svg onClick={() => setOpen(false)} height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                    </span>
                </DialogHeader>
                <DialogBody className='space-y-2 h-full'>
                    <Input
                        label='Title'
                        type='text'
                        value={formData.title}
                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        minLength={6}
                    />
                    <ReactQuill ref={reactQuillRef} style={{ height: "80%", maxHeight: "80%" }} theme="snow" modules={modules} value={value} onChange={setValue} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button loading={loading} variant="gradient" className='bg-[#0D182E]' onClick={addArticle}>
                        <span>Add</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openDelete} size='md' handler={handleOpenDelete}>
                <DialogHeader className='flex items-center justify-between'>
                    Delete Section
                    <span className='cursor-pointer'>
                        <svg onClick={() => setOpenDelete(false)} height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                    </span>
                </DialogHeader>
                <DialogBody className='space-y-2 h-full'>
                    <p>This action is irreversible, Are you sure you want to delete?</p>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setOpenDelete(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="red" onClick={deleteSection}>
                        <span>Delete</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div >
    )
}

export default SectionCard