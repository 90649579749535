import React from 'react'
import Navbar from '../components/navbar';
import DashboardModule from '../modules/dashboard';

const Dashboard = () => {
  return (
    <div>
      <Navbar />
      <DashboardModule />
    </div>
  )

}

export default Dashboard