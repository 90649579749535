import {
    Button,
    Card,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Alert,
    Textarea,
} from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { addDocument, addDocumentSection, deletePath, updateDoc } from '../utils/documents';
import { onValue, ref, update } from 'firebase/database';
import { database } from '../fireabase-config';
import SectionRow from './section-row';
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import LiveProject from "../assets/live-project.svg";
import LiveDoc from "../assets/doc-button.svg";
import { useNavigate } from 'react-router-dom';

const DocCard = ({ item, params }) => {
    const userName = localStorage.getItem("userName");
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openEditAlert, setOpenEditAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
    })
    const [formDataDoc, setFormDataDoc] = useState({ name: item.name, description: item.description, liveUrl: item.liveUrl });
    const [sections, setSections] = useState([]);
    const [docKey, setDocKey] = useState(item.key);
    const handleOpen = () => setOpen(!open);
    const handleOpenEdit = () => setEditOpen(!editOpen);
    const handleOpenDelete = () => setOpenDelete(!openDelete);
    const addSection = async () => {
        if (formData.name == "") {
            return;
        }
        setLoading(true);
        const userName = localStorage.getItem("userName");
        let nodeName = formData.name
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
        let position = sections.length > 0 ? sections[sections.length - 1].position + 1 : 0;
        let data = {
            name: formData.name,
            key: nodeName,
            status: 'Draft',
            position: position,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        }
        const addDocSection = await addDocumentSection({
            userName: userName,
            documentKey: item.key,
            nodeName: nodeName,
            data: data,
        })
        if (addDocSection == 1) {
            setFormData({ name: "" })
            setLoading(false);
            setOpen(false);
            setOpenAlert(true);
            setTimeout(() => {
                setOpenAlert(false);
            }, 2000);
        }
    }
    const getSections = async () => {
        onValue(ref(database, `documents/${params.username}/${item.key}/sections`), (snapshot) => {
            if (snapshot.val() == null) return;
            const data = snapshot.val();
            const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
            const sortedArray = dataArray.sort((a, b) => a.position - b.position);
            setSections(sortedArray);
        })
    }
    const getTaskPos = id => sections.findIndex(section => section.key === id);
    const handleDragEnd = event => {
        const userName = localStorage.getItem("userName");
        const { active, over } = event;
        if (active == null || over == null) return;
        if (active.id === over.id) return;
        const originalPos = getTaskPos(active.id);
        const newPos = getTaskPos(over.id);
        const activeItem = update(ref(database, `documents/${userName}/${item.key}/sections/${active.id}`), { position: newPos });
        const overItem = update(ref(database, `documents/${userName}/${item.key}/sections/${over.id}`), { position: originalPos });
    }
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )
    useEffect(() => {
        getSections();
    }, [item,])
    const deleteDocument = async () => {
        const res = await deletePath({ path: `documents/${params.username}/${item.key}` });
        if (res == 1) {
            setOpenDelete(false);
        }
    }
    const updateDocument = async () => {
        setUpdateLoading(true);
        const name = localStorage.getItem("userName");

        let data = {
            description: formDataDoc.description,
            liveUrl: formDataDoc.liveUrl,
        };
        const updateNewDoc = await updateDoc({ userName: name, nodeName: item.key, data: data })
        if (updateNewDoc == 1) {
            setUpdateLoading(false);
            setEditOpen(false);
            setOpenAlert(true);
            setTimeout(() => {
                setOpenAlert(false);
            }, 2000);
        }
    }
    return (
        <>
            <Alert
                open={openAlert}
                color="green"
                className="absolute right-3 top-2 w-[30%]"
                onClose={() => setOpenAlert(false)}
            >
                Document section added successfully!
            </Alert>
            <Alert
                open={openEditAlert}
                color="green"
                className="absolute right-3 top-2 w-[30%]"
                onClose={() => setOpenEditAlert(false)}
            >
                Document updated successfully!
            </Alert>
            <div className='p-2 space-y-2'>
                <div className='flex flex-col md:flex-row gap-3 md:gap-0 items-start justify-between'>
                    <div className='flex items-center gap-2'>
                        <img src={item.logo} className='object-contain w-16 h-16 border border-black rounded-full shadow-md' />
                        <div>
                            <p className='capitalize text-xl md:text-2xl font-medium'>{item.name}</p>
                            <p className='text-black'>{item.description}</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        {item.liveUrl &&
                            <a href={item.liveUrl} target='_blank'>
                                <div className='px-4 cursor-pointer select-none py-1 border border-[#0D182E] text-[#0D182E] rounded-md flex items-center justify-center gap-1'>
                                    Live Project
                                    <img src={LiveProject} />
                                </div>
                            </a>}
                        <div onClick={() => navigate(`/${params.username}/${item.key}`)} className='px-4 cursor-pointer select-none py-1 bg-[#0D182E] text-white rounded-md flex items-center justify-center gap-1'>
                            Full Documentation
                            <img src={LiveDoc} />
                        </div>
                        {params.username == userName && (
                            <>
                                <p onClick={() => setOpenDelete(true)} className='cursor-pointer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" /> </svg>
                                </p>
                                <span className='cursor-pointer'>
                                    <svg onClick={handleOpenEdit} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16"> <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" /> <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" /> </svg>
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className='flex items-start flex-wrap w-full gap-2'>
                    {sections.length > 0 && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCorners}
                            onDragEnd={handleDragEnd}
                        >
                            <SectionRow sections={sections} docKey={docKey} />
                        </DndContext>
                    )}
                    {params.username == userName && (
                        <Button onClick={handleOpen} className='border w-full md:w-[30%] h-24 capitalize border-[#0D182E] bg-[#F5F5F5] text-black text-xl font-medium'>
                            <span className='flex flex-row items-center justify-center gap-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" /> </svg>
                                Create Section
                            </span>
                        </Button>
                    )}
                </div>
            </div>
            <hr className='my-5' />
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Add Section</DialogHeader>
                <DialogBody className='space-y-2'>
                    <Input
                        label='Name'
                        type='text'
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        minLength={6}
                    />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button loading={loading} variant="gradient" className='bg-[#0D182E]' onClick={addSection}>
                        <span>Add</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openDelete} size='md' handler={handleOpenDelete}>
                <DialogHeader className='flex items-center justify-between'>
                    Delete Document
                    <span className='cursor-pointer'>
                        <svg onClick={() => setOpenDelete(false)} height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                    </span>
                </DialogHeader>
                <DialogBody className='space-y-2 h-full'>
                    <p>This action is irreversible, Are you sure you want to delete?</p>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setOpenDelete(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="red" onClick={deleteDocument}>
                        <span>Delete</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={editOpen} handler={handleOpenEdit}>
                <DialogHeader>Edit Document</DialogHeader>
                <DialogBody className='space-y-2'>
                    <Input
                        label='Url'
                        type='text'
                        value={formDataDoc.liveUrl}
                        onChange={(e) => setFormDataDoc({ ...formDataDoc, liveUrl: e.target.value })}
                    />
                    <Textarea
                        label='Description'
                        value={formDataDoc.description}
                        onChange={(e) => setFormDataDoc({ ...formDataDoc, description: e.target.value })}
                        minLength={50}
                        maxLength={245}
                    />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpenEdit}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button loading={updateLoading} onClick={updateDocument} variant="gradient" className='bg-[#0D182E]'>
                        <span>Update</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default DocCard