import React, { useState } from 'react'
import { Button, Dialog, DialogBody, DialogFooter, Input } from '@material-tailwind/react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, signInWithGooglePopup } from '../fireabase-config';
import { useNavigate } from 'react-router-dom';
import SocialButton from '../components/social-button';
import { addSocialSignupData, updateSocialSignupData, updateUserName } from '../utils/auth';
import LoginImage from "../assets/login-bg.png";
import UptechLogo from "../assets/Dlog.png";

const Login = () => {
    const navigate = useNavigate();
    const userName = localStorage.getItem("userName");
    const [user, loading] = useAuthState(auth);
    const [open, setOpen] = useState(false);
    const [newUserName, setUserName] = useState("");
    const [oldUserName, setOldUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [userLoading, setLoading] = useState(false);
    const [err, setErr] = useState("")
    const handleOpen = () => setOpen(!open);
    if (loading) {
        return <div>Loading</div>
    }
    if (userName) {
        navigate(`/${userName}`)
    }
    const loginGoogleUser = async () => {
        const response = await signInWithGooglePopup();
        if (response.user.metadata.creationTime == response.user.metadata.lastSignInTime) {
            let userName = response.user.displayName.toLowerCase().replace(/\s+/g, '-');
            const addData = await addSocialSignupData({ method: "Google", data: response, userName });
            if (addData == 1) {
                setOpen(true);
                setUserName(userName);
                setOldUserName(userName);
                setUserId(response.user.uid);
            }
        } else {
            const updateData = await updateSocialSignupData({ method: "Google", data: response });
            redirectToDashboard({ data: updateData });
        }
    }
    const updateUser = async () => {
        if (newUserName == "") {
            return;
        };
        if (newUserName == oldUserName) {
            localStorage.setItem("userName", newUserName);
            redirectToDashboard({ data: 1 });
        }
        setLoading(true);
        const addData = await updateUserName({ userName: newUserName, uid: userId });
        if (addData == 2) {
            setErr("Username already taken!")
            setLoading(false);
            return;
        }
        if (addData == 1) {
            redirectToDashboard({ data: addData });
        } else {
            setLoading(false);
            setOpen(false);
        }
    }
    const redirectToDashboard = ({ data }) => {
        const userName = localStorage.getItem("userName")
        if (data == 1) {
            navigate(`/${userName}`)
        }
    }
    return (
        <>
            {/* <div className="bg-hero-image flex items-center justify-center h-screen w-full">
            <div className="flex flex-col  justify-center items-center space-y-6">
                <div className="bg-white flex items-center gap-2 justify-center mt-[80px] p-3 rounded text-black mx-2">
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.5 0.398682L15.7411 9.15759L24.5 12.3987L15.7411 15.6398L12.5 24.3987L9.25891 15.6398L0.5 12.3987L9.25891 9.15759L12.5 0.398682Z"
                            fill="#001E00"
                        />
                    </svg>
                    <p className="font-medium text-sm lg:text-base leading-8 uppercase ">
                        FREE to use in Beta phase
                    </p>
                </div>
                <span
                    className=" text-[30px] px-1 md:text-[60px] font-semibold text-white text-center w-full lg:w-2/3  "
                    style={{ lineHeight: "1.3" }}
                >
                    Streamline Your Documentation Process
                </span>

                <p
                    className="text-sm md:text-base text-white text-center  w-full lg:w-1/2 px-8"
                    style={{ lineHeight: "2" }}
                >
                    Instantly Create Detailed Project Docs. Seamlessly Oversee Projects and Manage Versions Efficiently
                </p>
                <div className="flex flex-col  items-center gap-1 w-full">
                    <div className="hidden md:flex rounded-full bg-white   ">
                        <SocialButton
                            onClick={loginGoogleUser}
                            icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.0014 16.3114C30.0014 15.1603 29.9061 14.3203 29.6998 13.4492H16.2871V18.6447H24.1601C24.0014 19.9359 23.1442 21.8803 21.2394 23.1869L21.2127 23.3609L25.4536 26.5805L25.7474 26.6092C28.4458 24.167 30.0014 20.5736 30.0014 16.3114Z" fill="#4285F4" />
                                <path d="M16.2853 29.9998C20.1424 29.9998 23.3804 28.7553 25.7456 26.6086L21.2377 23.1863C20.0313 24.0108 18.4123 24.5863 16.2853 24.5863C12.5076 24.5863 9.30128 22.1441 8.15832 18.7686L7.99078 18.7825L3.58111 22.127L3.52344 22.2841C5.87261 26.8574 10.698 29.9998 16.2853 29.9998Z" fill="#34A853" />
                                <path d="M8.16061 18.769C7.85903 17.8979 7.6845 16.9645 7.6845 16.0001C7.6845 15.0356 7.85903 14.1023 8.14475 13.2312L8.13676 13.0456L3.67181 9.64746L3.52572 9.71556C2.55751 11.6134 2.00195 13.7445 2.00195 16.0001C2.00195 18.2556 2.55751 20.3867 3.52572 22.2845L8.16061 18.769Z" fill="#FBBC05" />
                                <path d="M16.2854 7.4133C18.9679 7.4133 20.7774 8.54885 21.8092 9.4978L25.8409 5.64C23.3648 3.38445 20.1425 2 16.2854 2C10.698 2 5.87262 5.1422 3.52344 9.71549L8.14247 13.2311C9.30131 9.85555 12.5076 7.4133 16.2854 7.4133Z" fill="#EB4335" />
                            </svg>}
                        />
                    </div>
                </div>
            </div>
        </div> */}
            <div className='w-full flex h-full'>
                <div className='w-1/2 hidden h-svh md:flex items-center justify-center'>
                    <div className='w-1/2 text-[#0D182E]'>
                        <p className='text-[32px] font-medium leading-10'>What is DLOG?</p>
                        <p className='text-lg leading-7 font-normal mt-3'>Create, organize, and share detailed documentation for your apps, websites, and more. Our platform allows you to easily write, collaborate, and publish documentation, making it accessible for your team and the wider community.</p>
                        <img src={LoginImage} alt='showcase' className='w-full mt-5 border-[3px] border-black rounded-lg' />
                    </div>
                </div>
                <div className='w-full md:w-1/2 h-svh bg-[#0D182E] text-white flex flex-col items-center justify-center'>
                    <img src={UptechLogo} alt='showcase' className='w-64' />
                    <p className='text-[32px] font-normal leading-10 mt-8'>Login to DLOG</p>
                    <p className='text-lg leading-7 mt-3'>Free in beta phase*</p>
                    <div onClick={loginGoogleUser} className='w-[80%] md:w-[40%] cursor-pointer select-none py-2 flex items-center justify-center gap-3 bg-white text-[#0D182E] rounded-lg mt-7'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.0014 16.3114C30.0014 15.1603 29.9061 14.3203 29.6998 13.4492H16.2871V18.6447H24.1601C24.0014 19.9359 23.1442 21.8803 21.2394 23.1869L21.2127 23.3609L25.4536 26.5805L25.7474 26.6092C28.4458 24.167 30.0014 20.5736 30.0014 16.3114Z" fill="#4285F4" />
                            <path d="M16.2853 29.9998C20.1424 29.9998 23.3804 28.7553 25.7456 26.6086L21.2377 23.1863C20.0313 24.0108 18.4123 24.5863 16.2853 24.5863C12.5076 24.5863 9.30128 22.1441 8.15832 18.7686L7.99078 18.7825L3.58111 22.127L3.52344 22.2841C5.87261 26.8574 10.698 29.9998 16.2853 29.9998Z" fill="#34A853" />
                            <path d="M8.16061 18.769C7.85903 17.8979 7.6845 16.9645 7.6845 16.0001C7.6845 15.0356 7.85903 14.1023 8.14475 13.2312L8.13676 13.0456L3.67181 9.64746L3.52572 9.71556C2.55751 11.6134 2.00195 13.7445 2.00195 16.0001C2.00195 18.2556 2.55751 20.3867 3.52572 22.2845L8.16061 18.769Z" fill="#FBBC05" />
                            <path d="M16.2854 7.4133C18.9679 7.4133 20.7774 8.54885 21.8092 9.4978L25.8409 5.64C23.3648 3.38445 20.1425 2 16.2854 2C10.698 2 5.87262 5.1422 3.52344 9.71549L8.14247 13.2311C9.30131 9.85555 12.5076 7.4133 16.2854 7.4133Z" fill="#EB4335" />
                        </svg>
                        <p>Sign in with Google</p>
                    </div>
                </div>
            </div>
            <Dialog open={open} handler={handleOpen}>
                <DialogBody className='space-y-2'>
                    <p className='text-xl font-bold text-black'>Username*</p>
                    <Input
                        label='Name'
                        type='text'
                        value={userName}
                        onChange={(e) => {
                            const newValue = e.target.value
                                .toLowerCase()
                                .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                .replace(/ /g, '-'); // Replace spaces with dashes
                            setUserName(newValue);
                        }}
                    />
                    {err && <p className='text-red-600 text-sm'>{err}</p>}
                    <p className='text-red-600 text-sm'>This is your username, you can edit it now, once you continue then you cannot edit it.</p>
                </DialogBody>
                <DialogFooter>
                    <Button loading={loading} variant="gradient" color="green" onClick={updateUser} >
                        <span>Continue</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default Login