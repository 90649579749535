import React from 'react'
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Article from './article';

const ArticleColumn = ({ articles, userName, docKey, sectionKey }) => {
    return (
        <div>
            <SortableContext items={articles} strategy={verticalListSortingStrategy}>
                {articles.map((item) => (
                    <Article key={item.key} id={item.key} item={item} userName={userName} docKey={docKey} sectionKey={sectionKey} />
                ))}
            </SortableContext>
        </div>
    )
}

export default ArticleColumn